<template>
  <div>
    <my-profile />
  </div>
</template>

<script>
import MyProfile from "../../components/auth/MyProfile";

export default {
  name: "profile",
  components: {
    MyProfile,
  },
};
</script>

<style lang="scss" scoped></style>
